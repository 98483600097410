import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import DataTable from "react-data-table-component";
import { url } from "../link";
const Showenquiry = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const [s, sets] = useState([]);
  const [pending, setPending] = useState(true);
  const column = [
    {
      name: "Eid",
      selector: (row) => row.Eid,
      sortable: true,
    },
    {
      name: "First_name",
      selector: (row) => row.First_name,
      sortable: true,
    },
    {
      name: "Full_name",
      selector: (row) => row.Full_name,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      sortable: true,
    },
    {
      name: "Course",
      selector: (row) => row.Course,
      sortable: true,
    },
    {
      name: "Enquiry_date",
      selector: (row) => row.Enquiry_date,
      sortable: true,
    },
    {
      name: "Call_date",
      selector: (row) => row.Call_date,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "Join",
      selector: (row) => row.Join,
      sortable: true,
    },
    {
      name: "Button",
      selector: (row) => row.Button,
      sortable: true,
    },
    {
      name: "History",
      selector: (row) => row.History,
      sortable: true,
    },
  ];

  const nav = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      axios.post(url + "/edusphere/enquiryreport").then((response) => {
        var e = ["All"];
        var r = response.data.map((k) => {
          if (e.indexOf(k[7].toLowerCase()) === -1) {
            e.push(k[7].toLowerCase());
          }

          return {
            Eid: k[0],
            First_name: k[1],
            Full_name: k[8],
            Mobile: k[2],
            Course: k[3],
            Enquiry_date: k[4],
            Call_date: k[5],

            Status: k[7],
            Join:
              k[7].toLowerCase() === "open" ? (
                <button className="btn btn-danger" onClick={() => reg(k)}>
                  Join
                </button>
              ) : (
                ""
              ),
            Button: (
              <button
                className="btn btn-primary"
                onClick={() => {
                  nav("/addenquiryremark", { state: k[0] });
                }}
              >
                Remark
              </button>
            ),
            History: (
              <button onClick={() => nexts(k)} className="btn btn-success">
                History
              </button>
            ),
          };
        });

        setData(r);
        setFilteredData(r);
        sets(e);
        setPending(false);
      });
      return () => clearTimeout(timeout);
    }, 1000);
  }, []);

  const nexts = (m) => {
    nav("/viewenquiry", { state: m });
  };

  const reg = (m) => {
    var x = m[0] + "-" + m[1] + "-" + m[2] + "-" + m[7];
    nav("/addregistration", { state: x });
  };

  const filterByStatus = (status) => {
    setSelectedStatus(status);

    if (status === "All") {
      setFilteredData(data);
    } else {
      const filteredResults = data.filter(
        (item) => item.Status.toLowerCase() === status.toLowerCase()
      );
      setFilteredData(filteredResults);
    }
  };
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
      },
    },
  };
  return (
    <>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h3>All Enquiry</h3>
          <NavLink to="/viewmobileenquiry">
            <button className="btn btn-primary" style={{ float: "right" }}>
              View Mobile Enquiry
            </button>
          </NavLink>
          <label htmlFor="statusFilter">Filter by Status:</label>

          <select
            id="statusFilter"
            value={selectedStatus}
            onChange={(e) => filterByStatus(e.target.value)}
          >
            {s.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
          <div className="dataTableContainer">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                const filtered = data.filter((item) =>
                  Object.values(item).some((value) => {
                    if (value !== null && value !== undefined) {
                      return value
                        .toString()
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase());
                    }
                    return false; // Handle null or undefined value, e.g., consider it not matching.
                  })
                );
                setFilteredData(filtered);
              }}
              placeholder="Search..."
            />
            <DataTable
              columns={column}
              data={filteredData}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="70vh"
              dense
              customStyles={customStyles}
              progressPending={pending}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Showenquiry;
