import axios from "axios";
import { useState, useEffect } from "react";
import Nav from "./Nav";
import { url } from "../link";
import { useLocation, useNavigate } from "react-router-dom";
const Addregistration = () => {
  const nav = useNavigate();
  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const { state } = useLocation();
  const [eid, seteid] = useState("");
  useEffect(() => {
    if (state !== null) {
      seteid(state);
    }
  }, []);

  const [courseid, setcourseid] = useState("");
  const [feejoined, setfeejoined] = useState("");
  const [nexpamentdate, setnexpamentdate] = useState("");
  const [bal, setbal] = useState("");
  const [examdate, setexamdate] = useState("");
  const [examstatus, setexamstatus] = useState("");
  const [mark, setmark] = useState("");
  const [attempt, setattempt] = useState("");
  const [qpaper, setqpaper] = useState("");
  const [classby, setclassby] = useState("");
  const [bookissue, setbookissue] = useState("");
  const [rstatus, setrstatus] = useState("joined");
  const [dob, setdob] = useState(MyDateString);
  const [data, setdata] = useState([]);
  const [course, setcourse] = useState([]);
  const [ori, setori] = useState();
  const [install, setinstall] = useState("0");
  const [sel, setsel] = useState([
    "full",
    "within 20 days",
    "installment",
    "special",
  ]);
  const [paylater, setpaylater] = useState(false);
  var x = 0;
  useEffect(() => {
    if (state !== null) {
      seteid(state);
    }
    axios.post(url + "/edusphere/searchdata").then((response) => {
      setdata(response.data["reg"]);
      setcourse(response.data["course"]);
    });
  }, []);
  const setvalue = (e) => {
    setcourseid(e);
    var k = e.split("-");
    setori(k[2]);
    setfeejoined(k[2]);
    setbal(k[2]);
  };
  const setoption = () => {
    return (
      <>
        <div className="form-floating mb-3 mt-3">
          <input
            list="browsers"
            name="browser"
            id="browser"
            className="form-control"
            value={eid}
            onChange={(e) => seteid(e.target.value)}
          />
          <datalist id="browsers">
            {data.map((d) => {
              return <option value={d} key={x++} />;
            })}
          </datalist>

          <label htmlFor="eid">eid</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            list="courses"
            name="course"
            id="browser"
            className="form-control"
            onChange={(e) => setvalue(e.target.value)}
          />
          <datalist id="courses">
            {course.map((d) => {
              return <option value={d} key={x++} />;
            })}
          </datalist>

          <label htmlFor="course">course</label>
        </div>
      </>
    );
  };
  const submitdata = () => {
    if (classby !== "") {
      const value = {
        eid: eid,
        courseid: courseid,
        feejoined: feejoined,
        nexpamentdate: nexpamentdate,
        bal: bal,
        examdate: examdate,
        examstatus: examstatus,
        mark: mark,
        attempt: attempt,
        qpaper: qpaper,
        classby: classby,
        bookissue: bookissue,
        rstatus: rstatus,
        dob: dob,
        paylater: paylater,
      };
      axios
        .post(url + "/edusphere/insertregistration", value)
        .then((response) => {
          if (paylater) {
            seteid("");
            setvalue("");
            setfeejoined("");
            setbal("");
            setinstall("");
            setclassby("");
            setdob("");
            setpaylater(false);
            setnexpamentdate("");
          } else {
            nav("/addbill", { state: response.data });
          }
        });
    } else {
      alert(" will the data correctly");
    }
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Student Registration</h1>
          {setoption()}
          <div className="form-floating mb-3 mt-3">
            <select
              className="form-control"
              onChange={(e) => {
                var k =
                  parseInt(ori) -
                  parseInt(ori) * (parseInt(e.target.value) / 100);
                console.log(k);
                setfeejoined(k);
                setbal(k);
                setinstall(e.target.value);
              }}
              value={install}
            >
              <option>Select Pattern</option>
              <option value="10">{sel[0]}</option>
              <option value="5">{sel[1]}</option>
              <option value="0">{sel[2]}</option>
              <option value="15">{sel[3]}</option>
            </select>
            <label htmlFor="Fees Pattern">Fees Pattern</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setfeejoined(e.target.value)}
              value={feejoined}
              placeholder="Enter feejoined"
              disabled
            />
            <label htmlFor="feejoined">feejoined</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setbal(e.target.value)}
              value={bal}
              placeholder="Enter bal"
              disabled
            />
            <label htmlFor="bal">bal</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <select
              className="form-control"
              onChange={(e) => setclassby(e.target.value)}
              value={classby}
            >
              <option>Select Class</option>
              <option>Hariharan</option>
              <option>Rajesh</option>
              <option>Rajasekar</option>
            </select>
            <label htmlFor="classby">classby</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setdob(e.target.value)}
              value={dob}
              placeholder="Enter Date of reg"
            />
            <label htmlFor="dob">Date of Reg</label>
          </div>
          <input
            class="form-check-input"
            type="checkbox"
            checked={paylater}
            onChange={() => {
              setpaylater(!paylater);
            }}
          />
          <label htmlFor="">Pay Later</label>
          {paylater && (
            <div className="form-floating mb-3 mt-3">
              <input
                type="date"
                className="form-control"
                onChange={(e) => setnexpamentdate(e.target.value)}
                value={nexpamentdate}
                placeholder="Enter nexpamentdate"
              />
              <label htmlFor="nexpamentdate">Payment Date</label>
            </div>
          )}

          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Addregistration;
