import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Nav from "../process/Nav";
import { url } from "../link";
const Viewprojectcommit = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [to, setto] = useState("");
  const [bal, setbal] = useState("");
  const [top, settop] = useState("");
  const [tow, settow] = useState("");
  const [toptow, settoptow] = useState("");
  const [count, setcount] = useState("");

  useEffect(() => {
    axios.post(url + "/edusphere/getcom").then((response) => {
      setData(response.data);
    });
  }, []);
  const viewproject = (e) => {
    nav("/addproject23payment", { state: [e[0], e[5], e[6], e[7]] });
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>pid</th>
                  <th>name</th>
                  <th>phone_number</th>
                  <th>college</th>
                  <th>project_title</th>
                  <th>total_amount</th>
                  <th>total_paid</th>
                  <th>Commitment</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewproject(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[8]}</td>
                      {d[9] === "requested" ? (
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              console.log();
                              axios
                                .post(
                                  url + "/edusphere/approveprojectcommentment",
                                  {
                                    prcid: d[10],
                                    status: "Accepted",
                                    commitedby: window.localStorage
                                      .getItem("user")
                                      .split(",")[0],
                                  }
                                )
                                .then((res) => {
                                  axios
                                    .post(url + "/edusphere/getcom")
                                    .then((response) => {
                                      setData(response.data);
                                    });
                                });
                            }}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              axios
                                .post(
                                  url + "/edusphere/approveprojectcommentment",
                                  {
                                    prcid: d[10],
                                    status: "Rejected",
                                    commitedby: window.localStorage
                                      .getItem("user")
                                      .split(",")[0],
                                  }
                                )
                                .then((res) => {
                                  axios
                                    .post(url + "/edusphere/getcom")
                                    .then((response) => {
                                      setData(response.data);
                                    });
                                });
                            }}
                          >
                            Rejected
                          </button>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewprojectcommit;
