import axios from "axios";
import { useState } from "react";
import Nav from "../process/Nav";
import Fileupload from "./Fileupload";
import { url } from "../link";
const Addproject2023 = () => {
  const [name, setname] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [college, setcollege] = useState("");
  const [project_title, setproject_title] = useState("");
  const [total_amount, settotal_amount] = useState("");
  const [total_paid, settotal_paid] = useState("");
  const [balance, setbalance] = useState("");
  const [vendor, setvendor] = useState("");
  const [vendortotal, setvendortotal] = useState("");
  const [paid, setpaid] = useState("");
  const [project_status, setproject_status] = useState("");
  const [report, setreport] = useState("");
  const [filename, setfilename] = useState("");
  const [ppt, setppt] = useState("");
  const submitdata = () => {
    const value = {
      name: name,
      phone_number: phone_number,
      college: college,
      project_title: project_title,
      total_amount: total_amount,
      total_paid: total_paid,
      balance: balance,
      vendor: vendor,
      vendortotal: vendortotal,
      paid: paid,
      project_status: project_status,
      report: report,
      filename: filename,
      ppt: ppt,
    };
    axios.post(url + "/edusphere/insertproject2023", value);
    alert("success");
    setname("");
    setphone_number("");
    setcollege("");
    setproject_title("");
    settotal_amount("");
    settotal_paid("");
    setbalance("");
    setvendor("");
    setvendortotal("");
    setpaid("");
    setproject_status("");
    setreport("");
    setfilename("");
    setppt("");
  };
  return (
    <div>
      <Nav />

      <h1>Add project</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setname(e.target.value)}
          value={name}
          placeholder="Enter name"
        />
        <label htmlFor="name">name</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setphone_number(e.target.value)}
          value={phone_number}
          placeholder="Enter phone_number"
        />
        <label htmlFor="phone_number">phone_number</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcollege(e.target.value)}
          value={college}
          placeholder="Enter college"
        />
        <label htmlFor="college">college</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setproject_title(e.target.value)}
          value={project_title}
          placeholder="Enter project_title"
        />
        <label htmlFor="project_title">project_title</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => {
            settotal_amount(e.target.value);
            setbalance(e.target.value);
          }}
          value={total_amount}
          placeholder="Enter total_amount"
        />
        <label htmlFor="total_amount">total_amount</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settotal_paid(e.target.value)}
          value={total_paid}
          placeholder="Enter total_paid"
        />
        <label htmlFor="total_paid">total_paid</label>
      </div> */}

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          value={balance}
          placeholder="Enter balance"
        />
        <label htmlFor="balance">balance</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setvendor(e.target.value)}
          value={vendor}
          placeholder="Enter vendor"
        />
        <label htmlFor="vendor">vendor</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setvendortotal(e.target.value)}
          value={vendortotal}
          placeholder="Enter vendortotal"
        />
        <label htmlFor="vendortotal">vendortotal</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpaid(e.target.value)}
          value={paid}
          placeholder="Enter paid"
        />
        <label htmlFor="paid">paid</label>
      </div>
      <h1>Kindly upload only zip file</h1>
      <div className="row">
        <div className=" mb-3 mt-3 col">
          <label htmlFor="project_status">Base Paper</label>
          <Fileupload
            name={project_title + "base"}
            setdata={setproject_status}
          />
        </div>

        <div className=" mb-3 mt-3 col">
          <label htmlFor="project_status">Report</label>
          <Fileupload name={project_title + "report"} setdata={setreport} />
        </div>
      </div>
      <div className="row">
        <div className=" mb-3 mt-3 col">
          <label htmlFor="project_status">Projectfile</label>
          <Fileupload name={project_title + "pro"} setdata={setfilename} />
        </div>

        <div className=" mb-3 mt-3 col">
          <label htmlFor="project_status">Ppt</label>
          <Fileupload name={project_title + "ppt"} setdata={setppt} />
        </div>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addproject2023;
