import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { url } from "../link";
import Navbar from "../components/Navbar";
const Viewbill = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const [s, sets] = useState([]);
  const [pending, setPending] = useState(true);

  const column = [
    {
      name: "Bid",
      selector: (row) => row.Bid,
      sortable: true,
    },
    {
      name: "Rid",
      selector: (row) => row.Rid,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "Full_name",
      selector: (row) => row.Full_name,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.Price,
      sortable: true,
    },

    {
      name: "Paid",
      selector: (row) => row.Paid,
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => row.Balance,
      sortable: true,
    },
    {
      name: "Paymentdate",
      selector: (row) => row.Paymentdate,
      sortable: true,
    },

    {
      name: "Nextpayment",
      selector: (row) => row.Nextpayment,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "Delete",
      selector: (row) => row.Delete,
      sortable: true,
    },
    {
      name: "Printbill",
      selector: (row) => row.Printbill,
      sortable: true,
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      axios.post(url + "/edusphere/viewbill").then((response) => {
        var e = ["All"];

        var r = response.data.map((k) => {
          if (e.indexOf(k[7].toLowerCase()) === -1) {
            e.push(k[7].toLowerCase());
          }
          return {
            Bid: k[0],
            Rid: k[1],
            Name: k[9],
            Full_name: k[11],
            Mobile: k[10],
            Price: k[2],
            Paid: k[3],
            Balance: k[4],
            Paymentdate: k[5],
            Nextpayment: k[6],
            Status: k[7],

            Delete: (
              <button className="btn btn-primary" onClick={() => deletec(k[0])}>
                Delete
              </button>
            ),
            Printbill: (
              <button className="btn btn-primary" onClick={() => viewbill(k)}>
                Printbill
              </button>
            ),
          };
        });

        setData(r);
        setFilteredData(r);
        sets(e);
        setPending(false);
      });
      return () => clearTimeout(timeout);
    }, 1000);
  }, []);

  const filterByStatus = (status) => {
    setSelectedStatus(status);

    if (status === "All") {
      setFilteredData(data);
    } else {
      const filteredResults = data.filter(
        (item) => item.Status.toLowerCase() === status.toLowerCase()
      );
      setFilteredData(filteredResults);
    }
  };
  const viewbill = (e) => {
    nav("/Printbill", { state: e });
  };
  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deletebill", {
        bid: e,
      })
      .then((response) => {
        const timeout = setTimeout(() => {
          axios.post(url + "/edusphere/viewbill").then((response) => {
            var e = ["All"];

            var r = response.data.map((k) => {
              if (e.indexOf(k[7].toLowerCase()) === -1) {
                e.push(k[7].toLowerCase());
              }
              return {
                Bid: k[0],
                Rid: k[1],
                Name: k[9],
                Full_name: k[11],
                Mobile: k[10],
                Price: k[2],
                Paid: k[3],
                Balance: k[4],
                Paymentdate: k[5],
                Nextpayment: k[6],
                Status: k[7],

                Delete: (
                  <button
                    className="btn btn-primary"
                    onClick={() => deletec(k[0])}
                  >
                    Delete
                  </button>
                ),
                Printbill: (
                  <button
                    className="btn btn-primary"
                    onClick={() => viewbill(k)}
                  >
                    Printbill
                  </button>
                ),
              };
            });

            setData(r);
            setFilteredData(r);
            sets(e);
            setPending(false);
          });
          return () => clearTimeout(timeout);
        }, 1000);
      });
  };

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
      },
    },
  };

  return (
    <div>
      <Navbar />
      <div className="main">
        <h3>All Bill</h3>
        <label htmlFor="statusFilter">Filter by Status:</label>

        <select
          id="statusFilter"
          value={selectedStatus}
          onChange={(e) => filterByStatus(e.target.value)}
        >
          {s.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
        <div className="dataTableContainer">
          <input
            type="search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);

              const filtered = data.filter((item) =>
                Object.values(item).some((value) => {
                  if (value !== null && value !== undefined) {
                    return value
                      .toString()
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  }
                  return false; // Handle null or undefined value, e.g., consider it not matching.
                })
              );
              setFilteredData(filtered);
            }}
            placeholder="Search..."
          />
          <DataTable
            columns={column}
            data={filteredData}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
    </div>
  );
};
export default Viewbill;
