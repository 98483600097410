import Nav from "../process/Nav";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../link";
const Dashboard = () => {
  const [y, sety] = useState({});

  const nav = useNavigate();
  useEffect(() => {
    var MyDate = new Date();
    var MyDateString =
      MyDate.getFullYear() +
      "-" +
      ("0" + (MyDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + MyDate.getDate()).slice(-2);

    var firstDay = new Date(MyDate.getFullYear(), MyDate.getMonth(), 1);
    var lastDay = new Date(MyDate.getFullYear(), MyDate.getMonth() + 1, 0);
    const value = {
      one: MyDateString,
      two:
        firstDay.getFullYear() +
        "-" +
        ("0" + (firstDay.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + firstDay.getDate()).slice(-2),
      three:
        lastDay.getFullYear() +
        "-" +
        ("0" + (lastDay.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + lastDay.getDate()).slice(-2),
    };
    axios.post(url + "/edusphere/dashboard", value).then((response) => {
      sety(response.data);
    });
  }, []);

  const check = (e) => {
    console.log(e);
    if (e === "moenq") {
      nav("/viewmobileenquiry");
    } else if (e !== "totalam") {
      nav("/check", { state: e });
    } else {
      nav("/check", { state: { v: "totalam", values: y, e: e } });
    }
  };
  const val = () => {
    var k = y["thismonth"] - y["totalamountavaiable"];
    if (k > 0) {
      return k;
    } else {
      return 0;
    }
  };
  const val1 = () => {
    if (val() !== 0) {
      return y["nextmonthexpense"];
    } else {
      var x =
        y["nextmonthexpense"] - (y["totalamountavaiable"] - y["thismonth"]);
      if (x > 0) {
        return x;
      } else {
        return 0;
      }
    }
  };
  return (
    <>
      <Nav />
      <div className="main">
        <div className="page-container">
          <div className="row">
            <NavLink className="nav-link col" to="/viewmonthlyexpense">
              <button className="btn btn-success text-white">
                Fixed Monthly Expense
              </button>
            </NavLink>
            <NavLink className="nav-link col" to="/viewexpense">
              <button className="btn btn-success text-white">
                Other Monthly Expense
              </button>
            </NavLink>
            <div className="row">
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Total Amount Avaiable
                  <br />
                  {y["totalamountavaiable"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                <h5 style={{ marginTop: "25%" }}>
                  This month need
                  <br />
                  {val()}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Next month need
                  <br />
                  {val1()}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("todaycol")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Today Collection
                  <br />
                  {y["collectiontoday"]}
                </h5>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("monthcol")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Monthly Collection
                  <br />
                  {y["monthcollection"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("todayenquiry")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Today enquiry
                  <br />
                  {y["todayenquiry"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("monthenq")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Month enquiry
                  <br />
                  {y["monthlyenquiry"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("todayreg")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Today Register
                  <br />
                  {y["todayreg"]}
                </h5>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("monthreg")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Month Register
                  <br />
                  {y["monthlyreg"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("todayexpense")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Today Expense
                  <br />
                  {y["todayexpense"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("monthexpense")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  MonthExpense
                  <br />
                  {y["monthlyexpense"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("thismonthpending")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  This month Pending
                  <br />
                  {y["thismonthpaymentbal"]}
                </h5>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("overdue")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Over Due
                  <br />
                  {y["overdue"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("pendingamount")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Pending amount
                  <br />
                  {y["pendingcollection"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("tpe")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  today pending
                  <br />
                  {y["tpe"]}
                </h5>
              </div>
              <div
                className="col-3 bg-success text-white"
                style={{
                  height: "25vh",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                onClick={(e) => check("moenq")}
              >
                <h5 style={{ marginTop: "25%" }}>
                  Mobile Enquiry
                  <br />
                  {y["menq"]}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
