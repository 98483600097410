import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Nav from "./Nav";
import Viewtable from "./Viewtable";
import { url } from "../link";
const Check = () => {
  const { state } = useLocation();
  const d = state;

  const nav = useNavigate();
  const [value, setvalue] = useState();

  useEffect(() => {
    var MyDate = new Date();
    var MyDateString =
      MyDate.getFullYear() +
      "-" +
      ("0" + (MyDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + MyDate.getDate()).slice(-2);

    var firstDay = new Date(MyDate.getFullYear(), MyDate.getMonth(), 1);
    var lastDay = new Date(MyDate.getFullYear(), MyDate.getMonth() + 1, 0);
    const value = {
      one: MyDateString,
      two:
        firstDay.getFullYear() +
        "-" +
        ("0" + (firstDay.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + firstDay.getDate()).slice(-2),
      three:
        lastDay.getFullYear() +
        "-" +
        ("0" + (lastDay.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + lastDay.getDate()).slice(-2),
    };
    axios.post(url + "/edusphere/checking", value).then((response) => {
      setvalue(response.data);
    });
  }, []);

  return (
    <>
      <div>
        <Nav />
        {d == "overdue" ? (
          <>
            <button
              className="btn btn-danger"
              style={{ float: "right" }}
              onClick={() => {
                axios.post(url + "/edusphere/senddata").then((response) => {
                  alert("whats up msg sent successfully");
                });
              }}
            >
              Send data
            </button>
          </>
        ) : (
          <></>
        )}
        <div className="main">{value && <Viewtable data={value[state]} />}</div>
      </div>
    </>
  );
};

export default Check;
