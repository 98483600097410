import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { url } from "../link";
const Viewprojectcommentment = () => {
  const { state } = useLocation();

  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  useEffect(() => {
    axios
      .post(url + "/edusphere/viewprojectcommentment", {
        id: state,
      })
      .then((response) => {
        setData(response.data);
        setvalue(response.data);
      });
  }, []);
  const viewprojectcommentment = (e) => {
    nav("/updateprojectcommentment", { state: e });
  };
  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deleteprojectcommentment", {
        id: e,
      })
      .then((response) => {
        axios
          .post(url + "/edusphere/viewprojectcommentment")
          .then((response) => {
            setData(response.data);
            setvalue(response.data);
          });
      });
  };
  const searchdata = (e) => {
    const r = [];

    for (var k of value) {
      var v = 0;

      for (var n of k) {
        n = "" + n;
        if (n.toLowerCase().indexOf(e) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setData(r);
  };

  return (
    <div>
      <Navbar />
      <h3>Project Commentment</h3>
      <input
        type="search"
        onChange={(e) => searchdata(e.target.value)}
        className="form-select"
        placeholder="Search"
      />
      <div className="table-responsive">
        <table className="table table-bordered" id="table_id">
          <thead>
            <tr>
              <th>prcid</th>
              <th>commited</th>
              <th>status</th>
              <th>pid</th>
              <th>by</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr key={d[0]}>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => viewprojectcommentment(d)}
                    >
                      {d[0]}
                    </button>
                  </td>
                  <td>{d[1]}</td>
                  <td>{d[2]}</td>
                  <td>{d[3]}</td>
                  <td>{d[4]}</td>
                  {/* <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => deletec(d[0])}
                    >
                      delete
                    </button>
                  </td> */}
                  {d[2] === "requested" ? (
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          console.log();
                          axios
                            .post(
                              url + "/edusphere/approveprojectcommentment",
                              {
                                prcid: d[0],
                                status: "Accepted",
                                commitedby: window.localStorage
                                  .getItem("user")
                                  .split(",")[0],
                              }
                            )
                            .then((res) => {
                              axios
                                .post(
                                  url + "/edusphere/viewprojectcommentment",
                                  {
                                    id: state,
                                  }
                                )
                                .then((response) => {
                                  setData(response.data);
                                  setvalue(response.data);
                                });
                            });
                        }}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          axios
                            .post(
                              url + "/edusphere/approveprojectcommentment",
                              {
                                prcid: d[0],
                                status: "Rejected",
                                commitedby: window.localStorage
                                  .getItem("user")
                                  .split(",")[0],
                              }
                            )
                            .then((res) => {
                              axios
                                .post(
                                  url + "/edusphere/viewprojectcommentment",
                                  {
                                    id: state,
                                  }
                                )
                                .then((response) => {
                                  setData(response.data);
                                  setvalue(response.data);
                                });
                            });
                        }}
                      >
                        Rejected
                      </button>
                    </td>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Viewprojectcommentment;
