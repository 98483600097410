import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../link";
import logo from "../components/logo512.jpg";
import Loading from "../components/Loading";
const Login = () => {
  const nav = useNavigate();
  const [x, setx] = useState(0);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const submitdata = () => {
    setx(1);
    const value = {
      email: email,
      password: password,
    };

    axios
      .post(url + "/edusphere/login", value)
      .then((response) => {
        if (response.data.length === 1) {
          console.log(response.data);
          window.localStorage.setItem("user", response.data[0]);
          window.localStorage.setItem("name", response.data[1]);
          nav("/enquiryentry");
        } else {
          alert("Email or password wrong");
          setx(0);
        }
      })
      .catch((res) => {
        setx(0);
      });
  };
  useEffect(() => {
    if (window.localStorage.getItem("user") !== null) {
      nav("/dashboard");
    }
  });
  const content = () => {
    if (x === 0) {
      return (
        <div className="row">
          <div className="col">
            <img src={logo} alt="" className="img-thumbnail" />
          </div>
          <div className="col">
            <div style={{ width: "50%", margin: "auto", marginTop: "25%" }}>
              <h1 style={{ textAlign: "center" }}>Office Login</h1>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setemail(e.target.value)}
                  value={email}
                  placeholder="Enter email"
                />
                <label htmlFor="Email">Enter Email</label>
              </div>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  placeholder="Enter Password"
                  onChange={(e) => setpassword(e.target.value)}
                />
                <label htmlFor="paid">Enter Password</label>
              </div>

              <input
                type="submit"
                className="btn btn-primary"
                onClick={submitdata}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  };

  return <>{content()}</>;
};
export default Login;
