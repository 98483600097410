import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../link";

import Nav from "../process/Nav";
import DataTable from "react-data-table-component";
const Viewmobileenquiry = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
      },
    },
  };
  const column = [
    {
      name: "Mid",
      selector: (row) => row.Mid,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      sortable: true,
    },
    {
      name: "Course",
      selector: (row) => row.Course,
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) => row.Remark,
      sortable: true,
    },

    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "Currentstatus",
      selector: (row) => row.Currentstatus,
      sortable: true,
    },
    {
      name: "Curdate",
      selector: (row) => row.Curdate,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "Delete",
      selector: (row) => row.Delete,
      sortable: true,
    },
    {
      name: "AddRemark",
      selector: (row) => row.Addremark,
      sortable: true,
    },
    {
      name: "ViewRemark",
      selector: (row) => row.Viewremark,
      sortable: true,
    },
  ];
  useEffect(() => {
    const timeout = setTimeout(() => {
      axios.post(url + "/edusphere/viewmobileenquiry").then((response) => {
        var r = response.data.map((k) => {
          return {
            Mid: (
              <button
                className="btn btn-primary"
                onClick={() => viewmobileenquiry(k)}
              >
                {k[0]}
              </button>
            ),
            Mobile: k[1],
            Course: k[2],
            Remark: k[3],
            Curdate: k[4],

            Name: k[6],
            Currentstatus: k[7],
            Status: k[5],
            Delete: (
              <button className="btn btn-primary" onClick={() => deletec(k[0])}>
                delete
              </button>
            ),
            Addremark: (
              <button
                className="btn btn-danger"
                onClick={() => {
                  nav("/addmobileremark", { state: k[0] });
                }}
              >
                Add
              </button>
            ),
            Viewremark: (
              <button
                className="btn btn-success"
                onClick={() => {
                  nav("/viewmobileremark", { state: k[0] });
                }}
              >
                View
              </button>
            ),
          };
        });

        setData(r);
        setFilteredData(r);

        setPending(false);
      });
      return () => clearTimeout(timeout);
    }, 1000);
  }, []);
  const [pending, setPending] = useState(true);
  const viewmobileenquiry = (e) => {
    nav("/updatemobileenquiry", { state: e });
  };
  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deletemobileenquiry", { id: e })
      .then((response) => {
        axios.post(url + "/edusphere/viewmobileenquiry").then((response) => {
          setData(response.data);
        });
      });
  };

  return (
    <div>
      <Nav />
      <h3>Mobileenquiry</h3>

      <div className="dataTableContainer">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            const filtered = data.filter((item) =>
              Object.values(item).some((value) =>
                value
                  .toString()
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              )
            );
            setFilteredData(filtered);
          }}
          placeholder="Search..."
        />
        <DataTable
          columns={column}
          data={filteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="70vh"
          dense
          customStyles={customStyles}
          progressPending={pending}
        />
      </div>
    </div>
  );
};
export default Viewmobileenquiry;
