import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { url } from "../link";
function Attendexam() {
  const nav = useNavigate();
  const [x, setx] = useState([]);
  const [i, seti] = useState(0);
  const [rid, setrid] = useState();
  useEffect(() => {}, []);
  const check = (x) => {
    var k =
      "width=" +
      (window.screen.availWidth + 500) +
      ",height=" +
      window.screen.availHeight +
      "fullscreen=yes focus=true";
    console.log(x);
    seti(0);
    window.localStorage.setItem("emid", x);

    window.open("/#/startexam", "_blank", k);
  };
  const working = () => {
    if (i === 0) {
      return (
        <>
          <div className="container" style={{ width: "50%", marginTop: "10%" }}>
            <h1 style={{ textAlign: "center" }}>Enter your Register id</h1>
            <input
              type="text"
              className="form-control"
              placeholder="Regid"
              value={rid}
              onChange={(e) => {
                setrid(e.target.value);
              }}
            />
            <br />

            <input
              type="submit"
              className="btn btn-primary form-control"
              onClick={() => {
                axios
                  .post(url + "/edusphere/viewexamforuser", {
                    eid: rid,
                  })
                  .then((response) => {
                    setx(response.data);
                    seti(1);
                  });
              }}
            />
          </div>
        </>
      );
    } else {
      if (x.length !== 0) {
        return (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Exam id</th>
                <th>Register id</th>
                <th>Start</th>
                <th>End</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {x.map((r) => {
                return (
                  <tr key={r[0]}>
                    <td>{r[0]}</td>
                    <td>{r[1]}</td>
                    <td>{r[2]}</td>
                    <td>{r[3]}</td>
                    <td>{r[4]}</td>
                    <td>
                      {r[4] === "scheduled" || r[4] === "resume" ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => check(r[0])}
                        >
                          Start exam
                        </button>
                      ) : r[4] === "started" ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => check(r[0])}
                        >
                          Resume exam
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      } else {
        return <h1 style={{ textAlign: "center" }}>no exam Avaiable</h1>;
      }
    }
  };
  return <>{working()}</>;
}
export default Attendexam;
