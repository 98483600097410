import { NavLink } from "react-router-dom";
import logoedusphere from "./images/logoedusphere.jpg";

const Header = () => {
  return (
    <>
      <div className="row">
        <div className="col m-4">
          <NavLink to="/">
            <img
              src={logoedusphere}
              alt="Edusphere Logo"
              width="30"
              height="30"
              className="d-inline align-text-top"
            />
            <p className="h6">EDUSPHERE SOLUTIONS</p>
          </NavLink>
        </div>
        <div className="dropdown col m-4">
          <div class="dropdown">
            <button
              type="button"
              class="btn btn-primary dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Course
            </button>
            <ul class="dropdown-menu">
              <NavLink className="dropdown-item" to="/c">
                C
              </NavLink>
              <NavLink className="dropdown-item" to="/cpp">
                C++
              </NavLink>
              <NavLink className="dropdown-item" to="/java">
                Java
              </NavLink>
              <NavLink className="dropdown-item" to="/python">
                Python
              </NavLink>
              <NavLink className="dropdown-item" to="/cs">
                C#
              </NavLink>
              <NavLink className="dropdown-item" to="/html">
                HTML
              </NavLink>
              <NavLink className="dropdown-item" to="/reactjs">
                ReactJs
              </NavLink>
              <NavLink className="dropdown-item" to="/mysql">
                MySQL
              </NavLink>
              <NavLink className="dropdown-item" to="/sqlserver">
                SQL Server
              </NavLink>
              <NavLink className="dropdown-item" to="/mongodb">
                MongoDB
              </NavLink>
              <NavLink className="dropdown-item" to="/javafullstack">
                Java Full Stack
              </NavLink>
              <NavLink className="dropdown-item" to="/pythonwebdevelopment">
                Python Web Development
              </NavLink>
              <NavLink className="dropdown-item" to="/javawebdevelopment">
                Java Web Development
              </NavLink>
              <NavLink className="dropdown-item" to="/datascience">
                Data Science
              </NavLink>
              <NavLink className="dropdown-item" to="/flask">
                Flask
              </NavLink>
              <NavLink className="dropdown-item" to="/django">
                Django
              </NavLink>
              <NavLink className="dropdown-item" to="/jsp">
                JSP
              </NavLink>
              <NavLink className="dropdown-item" to="/springboot">
                Spring Boot
              </NavLink>
              <NavLink className="dropdown-item" to="/hibernate">
                Hibernate
              </NavLink>
              <NavLink className="dropdown-item" to="/selenium">
                Selenium
              </NavLink>
              <NavLink className="dropdown-item" to="/tallyprime">
                Tally Prime
              </NavLink>
              <NavLink className="dropdown-item" to="/advexcel">
                Advanced Excel
              </NavLink>
              <NavLink className="dropdown-item" to="/msoffice">
                MS Office
              </NavLink>
              <NavLink className="dropdown-item" to="/ds">
                Data Structures
              </NavLink>
            </ul>
          </div>
        </div>
        {/* <div className="col m-4">
          <a href="http://www.edusphereprojects.online/" className=" ">
            <b>Project</b>
          </a>
        </div> */}
        <div className="col m-4">
          <NavLink to="/login" className=" ">
            <b>Login</b>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Header;
