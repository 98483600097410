import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../link";
import Navbar from "../components/Navbar";
import DataTable from "react-data-table-component";

const Viewattendance = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [filteredwithData, setFilteredwithData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const column = [
    {
      name: "Aid",
      selector: (row) => row.Aid,
      sortable: true,
    },
    {
      name: "Rid",
      selector: (row) => row.Rid,
      sortable: true,
    },
    {
      name: "First_name",
      selector: (row) => row.First_name,
      sortable: true,
    },
    {
      name: "Full_name",
      selector: (row) => row.Full_name,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      sortable: true,
    },

    {
      name: "ClassBy",
      selector: (row) => row.Classby,
      sortable: true,
    },
    {
      name: "Starttiming",
      selector: (row) => row.Starttime,
      sortable: true,
    },
    {
      name: "Endtiming",
      selector: (row) => row.Endtime,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "CourseName",
      selector: (row) => row.Course,
      sortable: true,
    },
    {
      name: "Days",
      selector: (row) => row.Dateaddes,
      sortable: true,
    },
    {
      name: "Skip till date",
      selector: (row) => row.Skiptilldate,
      sortable: true,
    },
    {
      name: "Today Status",
      selector: (row) => row.Todaystaus,
      sortable: true,
    },
    {
      name: "Dateadded",
      selector: (row) => row.Remark,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.Actions,
      sortable: true,
    },
  ];
  useEffect(() => {
    axios.post(url + "/edusphere/viewattendance").then((response) => {
      console.log(response.data);
      var r = response.data.map((k) => {
        return {
          Aid: k[0],
          Rid: k[1],
          First_name: k[2],
          Full_name: k[3],
          Mobile: k[4],
          Classby: k[5],
          Starttime: k[6],
          Endtime: k[7],
          Status: k[8],
          Course: k[9],
          Skiptilldate: k[10],
          Todaystaus: k[11],
          Dateaddes: k[12],
          Remark: k[13],
          Actions: k[14],
        };
      });
      setData(r);
      setValue(r);
      setFilteredwithData(r);
      setFilteredData(r);
      setPending(false);
    });
  }, []);

  const viewattendance = (e) => {
    nav("/updateattendance", { state: e });
  };

  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deleteattendance", { id: e })
      .then((response) => {
        axios.post(url + "/edusphere/viewattendance").then((response) => {
          setData(response.data);
          setValue(response.data);
        });
      });
  };
  const [pending, setPending] = useState(true);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
      },
    },
  };

  return (
    <div>
      <Navbar />
      <h3>Attendance</h3>
      <input
        type="search"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          const filtered = filteredwithData.filter((item) =>
            Object.values(item).some((value) => {
              if (value !== null && value !== undefined) {
                return value
                  .toString()
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase());
              }
              return false; // Handle null or undefined value, e.g., consider it not matching.
            })
          );
          setFilteredData(filtered);
        }}
        placeholder="Search..."
      />
      <div className="dataTableContainer">
        <DataTable
          columns={column}
          data={filteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="70vh"
          customStyles={customStyles}
          progressPending={pending}
        />
      </div>
    </div>
  );
};

export default Viewattendance;
