import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
import $ from "jquery";
const Viewexam = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.post(url + "/edusphere/viewexam").then((response) => {
      setData(response.data);
      console.log(response.data);
      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];

          n.scrollTop = n.scrollHeight;
        };

        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
  }, []);

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h3>Live Exam</h3>
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Eid</th>
                  <th>Rid</th>
                  <th>Subject Id</th>
                  <th>Starttime</th>
                  <th>Endtime</th>
                  <th>status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]} className="odd">
                      <td>{d[0]}</td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            axios
                              .post(url + "/edusphere/deleteexams", {
                                id: d[0],
                                rid: d[1],
                              })
                              .then((response) => {
                                axios
                                  .post(url + "/edusphere/viewexam")
                                  .then((response) => {
                                    setData(response.data);
                                    $(document).ready(function () {
                                      var eventFired = function (type) {
                                        var n = $("#demo_info")[0];

                                        n.scrollTop = n.scrollHeight;
                                      };

                                      $("#example")
                                        .on("order.dt", function () {
                                          eventFired("Order");
                                        })
                                        .on("search.dt", function () {
                                          eventFired("Search");
                                        })
                                        .on("page.dt", function () {
                                          eventFired("Page");
                                        })
                                        .DataTable();
                                    });
                                  });
                              });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewexam;
