import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Closewindow from "./closewindow";
import { useNavigate } from "react-router-dom";
import { url } from "../link";
import Timer from "./Timer";
const Examwindow = () => {
  const [v, setv] = useState(0);
  const [data, setdata] = useState();
  const [xr, setxr] = useState(0);

  const nav = useNavigate();
  useEffect(() => {
    axios
      .post(url + "/edusphere/showexam", {
        emid: window.localStorage.getItem("emid"),
      })
      .then((response) => {
        var k = [];
        for (var x of response.data) {
          var n = {};
          n["qid"] = x[0];
          n["quno"] = x[2];
          n["image"] = x[12];
          n["question"] = x[6];
          n["option1"] = x[7];
          n["option2"] = x[8];
          n["option3"] = x[9];
          n["option4"] = x[10];
          n["answer"] = x[11];
          n["selectanswer"] = x[3];
          n["rightorwrong"] = "";
          n["bookmark"] = false;
          n["mark"] = 0;
          n["qx"] = x[13];
          k.push(n);
        }
        setdata(k);
      });
  }, []);

  const sendexam = (e) => {
    setxr(e - 1);
    setv(0);
  };
  var xrr = {
    1: "c",
    2: "cplus",
    3: "java",
    4: "python",
    5: "mysql",
    6: "js",
  };
  const onChangeValue = (value, qid, x) => {
    var k = data.map((d) => {
      if (d.qid === qid) {
        d["selectanswer"] = value;
      }
      return d;
    });
    setdata(k);
  };
  const addbookmark = (id, x) => {
    var k = data.map((d) => {
      if (d.qid === id) {
        d["bookmark"] = !d["bookmark"];
      }
      return d;
    });
    setdata(k);
  };
  const checking = () => {
    var x = data[xr];
    console.log(x["image"]);
    if (x["image"] === "image") {
      return (
        <img
          src={
            url + "/static/" + "question/" + xrr[x["qx"]] + "/" + x["question"]
          }
          alt=""
        />
      );
    }
    {
      return <p>{x["question"]}</p>;
    }
  };
  var x = 0;
  const ren = () => {
    if (v === 1) {
      return (
        <>
          <div>
            {data.map((d) => {
              if (d["bookmark"]) {
                return (
                  <button
                    className="btn btn-danger"
                    key={x}
                    onClick={(r) => sendexam(r.target.innerHTML)}
                  >
                    {++x}
                  </button>
                );
              } else if (d["selectanswer"] !== "") {
                return (
                  <button
                    className="btn btn-success"
                    key={x}
                    onClick={(r) => sendexam(r.target.innerHTML)}
                  >
                    {++x}
                  </button>
                );
              } else if (d["selectanswer"] === "") {
                return (
                  <button
                    className="btn btn-primary"
                    key={x}
                    onClick={(r) => sendexam(r.target.innerHTML)}
                  >
                    {++x}
                  </button>
                );
              }
            })}
          </div>
        </>
      );
    } else {
      return (
        <div>
          <h5>
            {xr + 1}.{checking()}
          </h5>

          <div>
            <input
              type="radio"
              value={data[xr]["option1"]}
              name="gender"
              checked={data[xr]["option1"] === data[xr]["selectanswer"]}
              onChange={() =>
                onChangeValue(data[xr]["option1"], data[xr]["qid"], xr)
              }
            />
            {data[xr]["option1"]}
            <br />

            <input
              type="radio"
              value={data[xr]["option2"]}
              name="gender"
              checked={data[xr]["option2"] === data[xr]["selectanswer"]}
              onChange={() =>
                onChangeValue(data[xr]["option2"], data[xr]["qid"], xr)
              }
            />
            {data[xr]["option2"]}
            <br />

            <input
              type="radio"
              value={data[xr]["option3"]}
              name="gender"
              checked={data[xr]["option3"] === data[xr]["selectanswer"]}
              onChange={() =>
                onChangeValue(data[xr]["option3"], data[xr]["qid"], xr)
              }
            />
            {data[xr]["option3"]}
            <br />

            <input
              type="radio"
              value={data[xr]["option4"]}
              name="gender"
              checked={data[xr]["option4"] === data[xr]["selectanswer"]}
              onChange={() =>
                onChangeValue(data[xr]["option4"], data[xr]["qid"], xr)
              }
            />
            {data[xr]["option4"]}
            <br />
            <p>
              <input
                type="checkbox"
                checked={data[xr]["bookmark"]}
                onClick={() => {
                  addbookmark(data[xr]["qid"], xr);
                }}
              />
              Bookmark
            </p>
          </div>
        </div>
      );
    }
  };
  const next = () => {
    setv(0);
    var k = xr + 1;
    if (k < 30) {
      setxr(xr + 1);
    } else {
      setv(1);
    }
  };
  const previous = () => {
    setv(0);
    var k = xr - 1;
    if (k >= 0) {
      setxr(xr - 1);
    }
  };
  const submit = () => {
    axios
      .post(url + "/edusphere/submitanswer", {
        data: data,
        emid: window.localStorage.getItem("emid"),
      })
      .then((response) => {
        alert("Exam completed successfully");
        nav("/afterexam", { state: response.data });
      });
  };

  return (
    <>
      <Timer />
      <Closewindow />
      {data && ren()}
      <div className="row" style={{ marginTop: "30%" }}>
        <div className="col">
          <button className="btn btn-primary" onClick={() => next()}>
            Next
          </button>
        </div>
        <div className="col">
          <button className="btn btn-primary" onClick={() => previous()}>
            Previous
          </button>
        </div>
        <div className="col">
          <button className="btn btn-primary" onClick={() => setv(1)}>
            ExamSumary
          </button>
        </div>
        <div className="col">
          <button className="btn btn-danger" onClick={submit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default Examwindow;
